<template>
  <div class="mx-4" v-if="profileData">
    <div class="text-center ">
      <p class="text-xl">Account info</p>
    </div>
    <Form @submit="profileUpdate" class="pt-2" :validation-schema="validationSchema" v-slot="{ errors }" ref="form">
      <div class="grid grid-cols-3 gap-4 mb-4">
        <div class="col-span-1 pt-1 ">Picture</div>
        <div class="col-span-1 ml-5">
          <div class="relative flex w-full mt-2" v-if="previewAvatar">
            <img :src="previewAvatar" alt="" class="w-24 h-24 cursor-pointer" @click="$refs.file.click()" />
            <i class="
                flex
                cursor-pointer
                justify-center
                items-center
                bg-opacity-75
                fas
                fa-times
                text-red-500 text-xl
                absolute
                top-0
                right-0
                w-8
                h-8
                rounded-full
                bg-white
              " @click="deletePreview()"></i>
          </div>
          <div class="relative flex w-full mt-2" v-else-if="profileData.image && media_base_url">
            <img :src="media_base_url + profileData.image" alt="" class="w-24 h-24 cursor-pointer"
              @click="$refs.file.click()" />
            <i class="
                flex
                cursor-pointer
                justify-center
                items-center
                bg-opacity-75
                fas
                fa-times
                text-red-500 text-xl
                absolute
                top-0
                right-0
                w-8
                h-8
                rounded-full
                bg-white
              " @click="deleteUserPreview()"></i>
          </div>
          <div v-else>
            <img src="../../../assets/image/default.png" class="w-24 h-24" alt="" @click="$refs.file.click()" />
          </div>
          <input type="file" ref="file" class="hidden" @change="onFileChange" accept="image/*" />
          <ErrorMessage name="member_name" class="text-red-500 text-sm" />
        </div>
        <div class="col-span-1 mt-6">
          <button @click="$refs.file.click()" type="button" class=" text-white font-bold">Edit</button>
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 mb-4">
        <div class="col-span-1 pt-2 ">The Blast App</div>
        <div class="col-span-2 ml-5">
          <div class="flex">
            <div class="items-center pointer-events-none pt-1 ">@</div>
            <Field type="text" name="blast" placeholder="Enter" class="
                p-1
                placeholder-white
                form-control
                focus:outline-none
                w-full
              " :class="{ 'is-invalid': errors.blast }" v-model="profileData.blast" />
          </div>
          <ErrorMessage name="blast" class="text-red-500 text-sm" />
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 mb-4 text-sm">
        <div class="col-span-1 pt-1 ">Instagram</div>
        <div class="col-span-2 ml-5">
          <div class="flex">
            <div class="items-center pointer-events-none pt-1 ">
              instagram.com/
            </div>
            <Field type="text" name="instagram" placeholder="Enter" class="
                p-1
                placeholder-white
                form-control
                focus:outline-none
                w-full
              " :class="{ 'is-invalid': errors.instagram }" v-model="profileData.instagram" />
          </div>
          <ErrorMessage name="instagram" class="text-red-500 text-sm" />
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 mb-4 text-sm">
        <div class="col-span-1 pt-1 ">Soundcloud</div>
        <div class="col-span-2 ml-5">
          <div class="flex">
            <div class="items-center pointer-events-none pt-1 ">
              soundcloud.com/
            </div>

            <Field type="text" name="Soundcloud" placeholder="Enter" class="
                p-1
                placeholder-white
                form-control
                focus:outline-none
                w-full
              " :class="{ 'is-invalid': errors.soundcloud }" v-model="profileData.soundcloud" />
          </div>
          <ErrorMessage name="soundcloud" class="text-red-500 text-sm" />
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 mb-4 text-sm">
        <div class="col-span-1 pt-1 ">Youtube</div>
        <div class="col-span-2 ml-5">
          <div class="flex">
            <div class="
                items-center
                pointer-events-none
                pt-1
                focus:outline-none
                
              ">
              youtube.com/
            </div>
            <Field type="text" name="youtube" placeholder="Enter" class="p-1 placeholder-white form-control w-full"
              :class="{ 'is-invalid': errors.youtube }" v-model="profileData.youtube" />
          </div>
          <ErrorMessage name="email" class="text-red-500 text-sm" />
        </div>
      </div>
      <!-- <div class="text-center">
        <a class='text-large text-white  cursor-pointer'
          v-on:click="GenerateRcode()"> Generate R-code </a>
      </div> -->

      <div class="mt-8 mb-4 text-right">
        <button class="bg-btn rap-btn" :disabled="processing">Next</button>
      </div>
    </Form>
    <div class="absolute z-50 h-full w-full inset-0 bg-black bg-opacity-75" v-if="showCropper">
      <div class="w-10/12 h-4/6 mx-auto relative mt-20">
        <Cropper ref="cropper" minWidth="500" minHeight="500" :stencil-props="{ aspectRatio: 1 / 1, }"
          :src="previewAvatar" :canvas="{
            minHeight: 0,
            minWidth: 0,
            maxHeight: 2048,
            maxWidth: 2048,
          }">
        </Cropper>
        <!-- <vue-cropper ref="cropper" :src="previewAvatar" :aspect-ratio="1 / 1" minCropBoxWidth="200"
          minCropBoxHeight="200">
        </vue-cropper> -->
        <div class="mt-8 mb-4 text-right">
          <button class="bg-btn mr-4 bg-white rounded font-bold" @click="closeCropper()">
            Cancel
          </button>
          <button class="bg-btn rapbg rounded font-bold" @click="cropImage()">Select</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as yup from "yup";
import { Field, Form, ErrorMessage } from "vee-validate";
import { Cropper } from "vue-advanced-cropper";
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: "EditProfile",
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    Field,
    Form,
    ErrorMessage,
    Cropper,
  },
  computed: {
    user() {
      return this.$store.getters["User/user"];
    },
  },
  data() {
    const schema = yup.object({
      instagram: yup.string().nullable(),
    });

    return {
      media_base_url: process.env.VUE_APP_MEDIA_BASE_URL,
      previewAvatar: null,
      validationSchema: schema,
      profileData: null,
      showCropper: false,
      processing: false,
      lastAvatar: null,
    };
  },
  created() {
    this.profileData = { ...this.user };
  },
  methods: {
    profileUpdate() {
      this.processing = true;
      let data = this.profileData;

      if (this.lastAvatar) {
        data.image = this.lastAvatar;
      }
      var form_data = new FormData();
      for (var key in data) {
        form_data.append(key, data[key]);
      }

      API.post("edit-profile", form_data, { "Content-Type": "multipart/form-data" })
        .then((response) => {
          this.processing = false;
          this.lastAvatar = null;
          let offsetUser = response.user;
          offsetUser.token = response.token;
          offsetUser.profile_songs = response.profile_songs;
          offsetUser.artist_songs_count = response.artist_songs_count;

          // this.$store.commit("User/SET_USER", offsetUser);
          API.setUser(offsetUser);
          this.toast.success(response.message);
          // this.$emit("editProfile", response.user);
          this.$router.push({
            name: "ProfileWithSlug",
            params: { slug: offsetUser.slug },
          });
        })
        .catch(() => {
          this.lastAvatar = null;
          this.processing = false;
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      // this.previewImageUrl = URL.createObjectURL(file);
      this.imageToBase64(file);
    },
    GenerateRcode(){
      alert("hello");
      API.get().then().catch();
    },
    imageToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewAvatar = reader.result;
        this.showCropper = true;
      };
    },
    deletePreview() {
      this.previewAvatar = null;
      this.lastAvatar = null;
    },
    deleteUserPreview() {
      this.user.image = null
      this.$store.commit('User/SET_USER', this.user)
      this.profileData = { ...this.user };
    },
    closeCropper() {
      this.showCropper = false;
      this.previewAvatar = null;
      this.$refs.file.value = "";
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      const { canvas } = this.$refs.cropper.getResult();
      this.previewAvatar = canvas.toDataURL();
      canvas.toBlob(result => {
        this.lastAvatar = new File([result], "image");
      }, 'image/jpeg', 0.95);
      this.showCropper = false;
      this.$refs.file.value = "";
    },
  },
};
</script>
